<template>
    <div class="body-container">
        <BannerSection
                       title="Préstamos confiables: tu tranquilidad <br class='d-none d-lg-block'/>financiera comienza aquí" />
        <PerksComponent></PerksComponent>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">¿Necesitas un impulso económico sin complicaciones ni largas
                            esperas? En Ibancar te ofrecemos préstamos confiables que se ajustan a tus necesidades.
                        </h2>
                       <br>
                        <h3 style="font-size: 20px;">Ventajas del préstamo</h3>
                        
                    </span>
                    <p>En Ibancar entendemos que la necesidad de financiamiento puede surgir en cualquier momento.
                        Además, nuestro proceso eficiente y completamente digitalizado asegura que obtengas tu préstamo
                        de forma rápida y segura.</p>
                    <br>
                    <ul>
                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Disponibilidad inmediata:
                            </p>
                            <span>puedes obtener desde $12,000 MXN hasta $120,000 MXN rápidamente, para que no tengas
                                que esperar cuando más necesitas el dinero.</span>
                        </li>

                        <br>
                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Tasas competitivas:
                            </p>
                            <span>ofrecemos condiciones de préstamo transparentes con tasas competitivas y sin
                                comisiones ocultas, para que sepas exactamente qué esperar sin sorpresas
                                desagradables.</span>
                        </li>

                        <br>
                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Proceso 100% en línea:
                            </p>
                            <span>desde la solicitud hasta la recepción del dinero, todo el proceso se realiza en línea.
                                No necesitas visitar una sucursal ni perder tiempo en trámites presenciales. Aunque, si
                                lo deseas, puedes pasar por nuestra oficina y aclaramos tus dudas. </span>
                        </li>

                        <br>
                    </ul>
                    <p>Estas características están diseñadas para hacerte la vida más fácil y para que puedas resolver
                        tus necesidades financieras con confianza y sin interrumpir tu día a día.</p>
                    <br>
                </div>
            </div>
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">¿Cómo funciona?
                        </h3>
                        
                    </span>
                    <p>La transparencia y la seguridad son fundamentales cuando se trata de tus finanzas, y eso es lo
                        que te ofrecemos. Nuestro proceso de préstamo está diseñado para ser no solo rápido y fácil,
                        sino también seguro y confiable. </p>
                    <br>
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">Obtén préstamos confiables en 3 pasos simples
                        </h3>
                        
                    </span>
                    <ol>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Paso 1: Completa tu solicitud en línea
                            </h4>
                            <span>Ingresa a nuestro portal seguro y llena el formulario de solicitud con la información
                                básica y los detalles de tu vehículo. Nuestra plataforma utiliza tecnología de cifrado
                                de última generación para garantizar que toda tu información personal y financiera
                                permanezca privada y segura.</span>
                        </li>

                        <br>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Paso 2: Recibe aprobación rápida en 24 horas
                            </h4>
                            <span>Una vez enviada tu solicitud, nuestros expertos la revisarán utilizando sistemas
                                avanzados para asegurar una evaluación justa y rápida. Te notificaremos la aprobación de
                                tu préstamo de manera eficiente, generalmente en 24 horas, para que no tengas que
                                esperar.</span>
                        </li>
                        <br>

                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Paso 3: Recibe el dinero en tu cuenta
                            </h4>
                            <span>Tras la aprobación, el dinero será depositado directamente en tu cuenta bancaria. Todo
                                este proceso es realizado con los más altos estándares de seguridad digital para
                                garantizar tu tranquilidad financiera. </span>
                        </li>
                        <br>
                    </ol>

                    <br>
                </div>
            </div>
        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">Lo que necesitas para comenzar
                        </h3>
                       
                    </span>
                    <ul>
                        <li>
                            <span>Ser propietario de un automóvil con factura original. Esto demuestra la propiedad
                                legítima y facilita el proceso de evaluación de la garantía.</span>
                        </li>

                        <br>
                        <li>
                            <span>Identificación oficial vigente para verificar tu identidad y asegurar la transparencia
                                y la legalidad del proceso.</span>
                        </li>
                        <br>

                        <li>
                            <span>Comprobante de ingresos recientes para evaluar tu capacidad de pago.</span>
                        </li>
                        <br>
                        <li>
                            <span>Comprobante de domicilio.</span>
                        </li>
                    </ul>

                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">Recuerda, nuestros préstamos te ofrecen:
                        </h3>
                        
                    </span>
                    <ul>
                        <li>
                            <span>Rapidez</span>
                        </li>

                        <br>
                        <li>
                            <span>Seguridad</span>
                        </li>
                        <br>

                        <li>
                            <span>Flexibilidad</span>
                        </li>
                        <br>
                    </ul>

                    <h3 class="bold-text"
                        style="font-size: 20px;">¡Obtén préstamos confiables y rápidos con la garantía de tu auto sin
                        dejar de utilizarlo!</h3>
                    
                    <h4 style="font-size: 20px;">Con nosotros, el proceso es simple y totalmente transparente. Aprovecha
                        la oportunidad de obtener financiamiento inmediato basado en el valor de tu auto, sin afectar tu
                        movilidad diaria. </h4>

                   
                </div>
            </div>
        </div>

        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosConfiablesYRapidos',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Préstamos confiables | Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Préstamos confiables y seguros con tu auto como aval. Tasaciones justas, plazos flexibles y sin sorpresas. ¡Dinero rápido y con total confianza!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos confiables'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamos-sin-buro', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>